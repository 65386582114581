@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
html body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
button {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  outline: 0;
}
button:focus {
  outline: 0;
}
.ul__need__points ul {
  list-style-type: disc!important;
  margin-left: 20px;
}
.ur-btn {
  padding: 9px 12px;
  /* background: #fff; */
  /*border: 1px solid #000;*/
  border-radius: 8px;
  line-height: 16px;
  font-weight: 400;
  font-size: 12px;
}
/* .ur-btn.ur-btn-secondary {
  background: #eceff5;
  border-color: #eceff5;
  color: #484151;
}

*/
input {
  margin: 0;
}
.ur-container {
  margin: 50px auto 0;
  max-width: 1160px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

.steps-container {
  font-size: 22px;
  margin-bottom: 40px;
}

@media (max-width: 600px) {
  .steps-container {
    font-size: 12px;
  }
}

.step-number {
  font-weight: 700;
  color: #01bac6;
}

.step-text {
  color: #01bac6;
}

.step-arrow {
  color: #01bac6;
  margin: 0 10px;
}

.inactive-step-number {
  font-weight: 700;
  color: #d6d6d6;
}

.inactive-step-text {
  color: #d6d6d6;
}

.ur-row {
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
}
.ur-d-block {
  display: block;
}
.ur-d-inline-block {
  display: inline-block;
}
.ur-d-none {
  display: none !important;
}
.ur-text-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ur-pb-4 {
  padding-bottom: 2rem !important;
}
.ur-pb-3 {
  padding-bottom: 1.143rem !important;
}
.ur-pr-3 {
  padding-right: 1.143rem !important;
}
.ur-pb-2 {
  padding-bottom: 1rem !important;
}
.ur-pr-2 {
  padding-right: 1rem !important;
}
.ur-pb-1 {
  padding-bottom: 0.6rem !important;
}
.ur-pr-1 {
  padding-right: 0.6rem !important;
}
.ur-p-0 {
  padding: 0 !important;
}
.ur-mt-3 {
  margin-top: 1.143rem !important;
}
.ur-mt-2 {
  margin-top: 1rem !important;
}
.ur-mt-1 {
  margin-top: 0.571rem !important;
}
.ur-mb-2 {
  margin-bottom: 1rem !important;
}
.ur-mb-1 {
  margin-bottom: 0.571rem !important;
}
.ur-mr-2 {
  margin-right: 1rem !important;
}
.ur-mr-1 {
  margin-right: 0.571rem !important;
}

.ur-mt-7px {
  margin-top: 7px;
}

.ur-m-0 {
  margin: 0 !important;
}

@keyframes ur-spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.ur-spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.3em solid #01bac6;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite ur-spinner-border;
  animation: 0.75s linear infinite ur-spinner-border;
}
.ur-spinner-border {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}
.ur-spinner-border-white {
  border-color: #fff;
  border-right-color: transparent;
}
.ur-spinner-border-sm {
  width: 0.8rem;
  height: 0.8rem;
  border-width: 0.2em;
}

.ur-visually-hidden,
.ur-visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.react-tiny-popover-container {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  max-width: 460px;
  z-index: 99;
}
.flex {
  display: flex;
}
.text-boomboom{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.text-crusader{
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}
.text-snoops{
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
}
.color-primary{
  color: #1F1F1F;
}

.color-agla{
	color: #9F9F9F;
}
.color-red{
	color: #DE210B;
}

