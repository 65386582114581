.ur-row-near-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ur-row-near-container>.icon-button {
  display: none;
}
.ur-row-near {
  background-color: #f5f5f5;
}

.ur-row-near-container .search-form__button-submit {
  margin-bottom: 10px;
}

.nearby-dates-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-direction: row;
  max-width: 1120px;
}

.date-item-near {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px 20px;
  /* width: 13%; */
}

.date-item-near.selected {
  border-top-width: 3px;
    border-top-style: solid;
  padding: 7px 10px 20px;
}

.date-item-near.selected+.ur-row-near {
  border-bottom: 3px solid #01BAC6 !important;
}

/* .date-item-near.selected .date-near,
.date-item-near.selected .weekday-near,
.date-item-near.selected .price-near {
  color: #01BAC6 !important;
}

*/
.date-near {
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #929292;
}

.price-near {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
}

.cancel-icon-near {
  width: 24px;
  height: 24px;
  fill: #929292;
  margin-top: 10px;
}

.best-price {
  position: relative;
}

.best-price-badge {
  position: absolute;
  min-width: 100px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffbd00;
  color: #fff;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 5px;
}

.progress-container {
  display: flex;
  justify-content: center;
  height: 10vh;
}

.progress-circle {
  margin-top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #606e8c;
  animation: spin 1s infinite linear;
}

.progress-circle__preloader {
  width: 80px!important;
  height: 80px!important;
}

.parent.loading {
  cursor: wait !important;
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 575px) {
  .best-price-badge {
    position: absolute;
    width: 76px;
    height: 23px;
    top: 28px;
    left: 49%;
    transform: translateX(-50%);
    background-color: #ffbd00;
    color: #fff;
    padding: 0px 5px;
    font-size: 10px;
    line-height: 20px;
    border-radius: 5px;
  }

  .price-near {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 7px;
  }

  .date-item-near {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 13px 2px 4px;
    width: 30%;
  }

  .date-near {
    font-family: Inter;
    font-size: 15px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #929292;
  }

  .date-item-near.selected {
    border-top: 1px solid #01BAC6;
    padding: 13px 2px 4px;
    background-color: #ffffff;
  }

  .near-sliding {
    transform: scale(1.4);
  }

  .near-sliding-left {
    margin-left: 5px;
  }

  .near-sliding-right {
    margin-right: 5px;
  }
}
@media (max-width: 959px) {
  .ur-row-near-container>.icon-button {
    display: flex;
  }
}