.result-container {
  margin-top: 20px;
}

.warning-container {
  padding: 24px;
  display: flex;
  color: #f0a30a;
  font-weight: 500;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 20px;
  background-image: linear-gradient(
    rgba(240, 163, 10, 0.12),
    rgba(240, 163, 10, 0.12)
  );
}

.non-result {
  text-align: center;
  font-size: 18px;
  padding-top: 60px;
  animation: showDiv 2s forwards;
}

.warning-new-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
  gap: 20px;
  /* background: #F5F5F5; */
  border-radius: 24px;
  cursor: context-menu;
}

.warning-new-display-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.warning-new-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 154px;
  height: 70px;
}

.warning-new-no-rooms-title {
  width: 75vw;
  height: 32px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  /* color: #000000; */
}

.warning-new-no-rooms-message {
  width: 634px;
  height: 22px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  /* color: #9F9F9F; */
}

.warning-new-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;
  width: 245px;
  height: 56px;
  /* background: #DE210B; */
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}

.warning-new-notify-button {
  width: 215px;
  height: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  flex-grow: 0;
}

.subscription__modal {
  display: flex;
  flex-direction: column;
}

.title__subscription__modal {
  color: #1F1F1F;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.desc__subscription__modal {
  margin-top: 20px;
  color: #9F9F9F;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}

.dates__guests__subscription__container {
  display: flex;
  padding: 16px 0 10px;
  margin-top: 20px;
}

.dates__guests__subscription__element {
  background-color: #ECECEC;
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  gap: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #1F1F1F;
  margin-right: 15px;
}

.hr__class {
  border-bottom: 1px #D6D6D6 solid
}

.email__subscription {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 16px;
  padding: 16px;
  margin-top: 20px;
  border: none;
  outline: none;
  /* width: 93%; */
}

.error__email__validation {
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #DE210B;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 7px;
}

.button__subscription {
  background-color: #ECECEC;
  margin-top: 20px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  color: #9F9F9F;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.button__subscription__pre__send {
  color: #FFFFFF;
  background-color: #DE210B;
  margin-top: 20px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.new__modal {
  background-color: #F5F5F5;
  border-radius: 16px;
  padding: 16px;
  /* width: 95%; */
  margin-top: -4px;
  gap: 16px;
}

.new__modal__mobile {
  height: 75vh;
  overflow-y: scroll;
}

.rs-modal-content {
  border-radius: 24px!important;
}

.subscription__footer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #9F9F9F;
  font-family: Inter;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  margin-top: 12px;
}

.room__type__container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-top: 15px;
}

.title__room__type__container {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #1F1F1F;
}

.room__type__element {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.room__type__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.room__type__input+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.room__type__input+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  position: relative;
  top: 20%;
}
.room__type__input:checked+label::before {
  border-color: #DE210B;
  background-color: #DE210B;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3e%3cpath fill='%23fff' d='M11.2935 0.259425C11.5867 0.58512 11.5651 1.09117 11.2452 1.38973L4.38807 7.78972C4.08768 8.07009 3.62661 8.07009 3.32622 7.78972L0.754792 5.38972C0.434913 5.09117 0.413304 4.58512 0.706526 4.25942C0.999749 3.93373 1.49677 3.91173 1.81664 4.21028L3.85714 6.11475L10.1834 0.210281C10.5032 -0.0882725 11.0003 -0.0662702 11.2935 0.259425Z'/%3e%3c/svg%3e");
}

.room__type__name__price__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.room__type__name {
  padding-bottom: 5px;
  overflow: hidden;
  display: -webkit-box!important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #1F1F1F;
  cursor: pointer;
  margin-top: 5px;
}

.room__type__price {
  margin-left: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: right;
  white-space: nowrap;
  color: #9F9F9F;
}

.show__more {
  color: #9F9F9F;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 15px;
}

.succes__desc {
  margin-top: 15px;
  color: #9F9F9F;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.succes__title {
  color: #1F1F1F;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.alert-blocks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rs-modal-header .rs-modal-header-close {
  font-size: 15px;
  margin: 5px;
}
.preloader-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 20px;
  grid-template-areas:
    ". .";
}

.preloader-container__left {
  align-self: center;
  justify-self: end;

}

.preloader-container__right {
  align-self: center;
  justify-self: start;
}

.preloader-container-timeout {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-container,
.preloader-container-timeout {
  padding-bottom: 100px;
}
.preloader-container.preloader-only,
.preloader-container-timeout.preloader-only {
  padding-bottom: 0px;
  height: 80vh;
}

.preloader-text {
  font-size: 16px;
  color: #606e8c;
  font-weight: 500;
  animation: fadeFromDown 0.5s forwards;
}

.preloader-container-timeout .preloader-text {
  text-align: center;
}

.fade-out-and-up {
  animation: fadeOutAndUp 0.5s forwards;
}

@media (max-width: 767px) {
  .warning-new-container {
    padding: 45px;
  }

  .warning-new-no-rooms-title {
    font-size: 24px;
  }

  .warning-new-no-rooms-message {
    width: 91vw;
    margin-top: 30px;
  }

  .warning-new-button-container {
    margin-top: 55px;
  }

  .dates__guests__subscription__container {
    margin-top: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .dates__guests__subscription__element {
    margin-bottom: 5px;
  }

  .title__room__type__container {
    text-align: left;
  }
}


@keyframes showDiv {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fadeFromDown {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOutAndUp {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}