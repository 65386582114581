/* Icons */
.icon {
  display: inline-block;
  width: 0.875em;
  height: 0.875em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 0.167em;
  position: relative;
  font-style: normal;
}
.icon.icon-check {
  border: 1px solid #cccccc;
  border-radius: 3px;
}

/* Icons end*/
button {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.react-fancybox .box {
  z-index: 999;
}

.bounce-in {
  -webkit-animation-fill-mode: initial;
  animation-fill-mode: initial;
}

.rs-modal.rs-modal-xs {
  width: auto;
  max-width: 400px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 60px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  z-index: 999;
}

.modal__bk {
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%!important;
  display: block!important;
  height: auto!important;
  max-width: 900px!important;
}

.modal__bk__mobile {
  max-width: 500px!important;
  overflow-y: scroll;
}

.modal__bk__mobile__upsale {
  margin-bottom: 80px !important;
}

.modal__bk.rate-description-modal {
  overflow-y: auto;
  max-height: 90vh;
  max-width: 400px;
  padding: 40px;
}

.modal-header {
  position: absolute;
  right: 0;
  z-index: 1000;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 35px!important;
  cursor: pointer;
}

@media screen and (max-width: 959px) {
  .header__menu-switch {
    display: block;
  }
  .header__controls {
    display: none;
  }
}
