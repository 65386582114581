.hotellist-modal__button-opener {
  background-color: #F5F5F5;
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  /* color: #000; */
}

.hotellist-modal__button-opener-title {
  margin-left: 10px;
  margin-top: 3px;
}

.hotellist-modal-content {
  /* border: 3px solid red; */
  height: calc(100% - 10px);
  top: 32px;
  /* left: 32px; */
  border-radius: 16px;
  padding: 15px;
}

.popup-content.hotellist-modal-content {
  width: 75%;
  padding: 12px 20px 20px 20px;
  max-width: 1000px;
  @media (max-width: 1160px) {
    /* Нужен, поскольку на проде отображается footer, перекрывающий модалку */
    padding-bottom: 100px;
  }

  @media (max-width: 880px) {
    width: 95%;
  }
  @media (max-width: 598px) {
    width: 100vw;
    padding: 12px 0 20px 0;
  }
}

.hotellist-modal__header {
  display: flex;
  justify-content: space-between;
}

.hotellist-modal__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #000000; */
  margin-bottom: 12px;
  text-indent: 12px;
}

.hotellist-modal__close-btn {
  height: 32px;
  width: 32px;
  background-image: url('/bookform/images/icons/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  /* background-color: #ECECEC; */
  cursor: pointer;
}

.hotellist-modal__subheader {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #000000; */
}

.hotellist-modal__wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 16px;
}

.hotellist-modal__hotel-block {
  display: flex;
  /* justify-content: space-between; */
  /* background-color: #f5f5f5; */
  padding: 15px;
  border-radius: 12px;
  margin-top: 20px;

  @media (max-width: 598px) {
    flex-direction: column;
  }
}

.hotellist-modal__hotel-block>div:first-child {
  /* border: 1px solid red; */
  flex-grow: 1;
}

.hotellist-modal__hotel-block>div:nth-child(2) {
  /* border: 1px solid blue; */
  flex-grow: 4;
}

.hotellist-modal__hotel-block>div:nth-child(3) {
  display: flex;
  flex-direction: column;
  /* border: 1px solid yellow; */
  flex-grow: 1;
  justify-content: space-between;
  align-items: end;
  @media (max-width: 598px) {
    align-items: flex-start;
    flex-direction: row;
    display: inline;
    /* margin-top: 20px; */
  }
}

.hotellist-modal__hotel-select-btn {
  align-self: flex-end;
}

.hotellist-modal__hotel-image {
  width: 145px;
  height: 96px;
  max-width: 145px;
  max-height: 96px;
  border-radius: 8px;
  /* border: 1px solid green; */
  margin-right: 15px;
  @media (max-width: 598px) {
    width: 64px;
    height: 64px;
    max-width: 64px;
    max-height: 64px;
  }
}

.hotellist-modal__hotel-image>img {
  width: 145px;
  height: 96px;
  max-width: 145px;
  max-height: 96px;
  border-radius: 8px;
  @media (max-width: 598px) {
    width: 64px;
    height: 64px;
    max-width: 64px;
    max-height: 64px;
  }
}

.hotellist-modal__hold-head-598 {
  display: flex;
}

.hotellist-modal__hotel-name {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 700;
  /* color: #000000; */
}

.hotellist-modal__hotel-stars {
  display: flex;
}

.hotellist-modal__hotel-star {
  background-image: url('/bookform/images/icons/star.svg');
  width: 16px;
  height: 16px;
}

.hotellist-modal__hotel-name-wrap {
  display: flex;
  align-items: center;
  @media (max-width: 598px) {
    display: block;
  }
}

.hotellist-modal__hotel-description {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #9f9f9f;
  padding: 5px 0;
}

.hotellist-modal__hotel-loader {
  justify-content: center !important;
  @media (max-width: 598px) {
    margin: auto;
  }
}

.hotellist-modal__hotel-services {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.hotellist-modal__hotel-price {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  /* color: #000; */
}

.hotellist-modal__hotel-hold {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.hotellist-modal__hotel-price-hold {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hotellist-modal__hotel-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  /* color: #9f9f9f; */
  text-align: end;
}

#hotellist-modal__hotel-select-btn,
#hotellist-modal__hotel-btn-disabled {
  width: 94px;
  height: 44px;
  padding: 10px, 16px, 10px, 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

/* #hotellist-modal__hotel-select-btn {
  color: white;
  background-color: #de210b;
}

*/

#hotellist-modal__hotel-btn-disabled {
  color: #9F9F9F;
  background-color: #ECECEC;
  pointer-events: none;
}