.result-container__hotel .result-container__hotel-more .row-description {
  border-bottom: 1px solid #eceff5;
}
.result-container__hotel
  .result-container__hotel-more
  .row-description:last-child {
  border-bottom: 0;
}
.result-container__hotel
  .result-container__hotel-more
  .row-description
  .ur-col {
  padding: 24px;
  box-sizing: border-box;
}
.result-container__hotel .result-container__hotel-more .ur-col.ur-col-1 {
  /* color: #484151; */
}
.result-container__hotel .result-container__hotel-more .ur-col.ur-col-1.has-bg {
  background-size: cover;
  color: #fff;
}
.result-container__hotel
  .result-container__hotel-more
  .ur-col.ur-col-1.has-bg
  .ur-btn.ur-btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
.result-container__hotel
  .result-container__hotel-more
  .row-description
  .ur-col.ur-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.result-container__hotel
  .result-container__hotel-more
  .row-description
  .ur-col.ur-col-3 {
  padding-left: 0;
  padding-right: 22px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.result-container__hotel .result-container__hotel-more .h4 {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}
.result-container__hotel .result-container__hotel-more p {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
  margin: 0 0 8px 0;
}
.result-container__hotel
  .result-container__hotel-more
  .ur-btn.ur-btn-secondary {
  margin-top: 4px;
}
.hotel-content__services {
  position: relative;
  width: calc(100% - 16px);
  height: 112px;
  margin-bottom: 40px;
}
.hotel-content__services .services-wrapper {
  height: 112px;
  overflow: hidden;
  position: relative;
}
.rs-modal .hotel-content__services {
  height: auto;
}
.hotel-content__services .services-stage {
  display: flex;
  position: absolute;
  left: 0;
  -webkit-transition: left ease-out 0.3s;
  -moz-transition: left ease-out 0.3s;
  -o-transition: left ease-out 0.3s;
  transition: left ease-out 0.3s;
}
.hotel-content__services .services-item {
  width: 144px !important;
  height: 112px;
  border-width: 1px;
    border-style: solid;
    /* background: #ffffff; */
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.hotel-content__services .services-item .icon {
  width: 32px;
  height: 32px;
  font-size: 28px;
  /* color: #01bac6; */
}
.hotel-content__services .services-item .icon.undefined:before {
  content: "☆";
}
.hotel-content__services .services-item p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #484151;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.hotel-price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
  margin-top: 10px;
}
.bonus-container+.hotel-price{
  margin-top: 30px;
}
.hotel-price:last-child {
  margin-bottom: 0;
}
.hotel-price .hotel-price__col {
  margin-left: 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #929292;
}
.hotel-price .hotel-price__col span.plus {
  color: #929292;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.02em;
}
.hotel-price .hotel-price__col:first-child {
  margin-left: 0;
}
.hotel-price .ur-btn.ur-btn-buy {
  position: relative;
  padding: 6px 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.hotel-price .ur-btn.ur-btn-buy.load {
  color: #ffbd00;
}
.hotel-price .col-price {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #484151;
}
.col-price {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.children-info-div-style {
  position: absolute;
  top: -26px;
  font-size: 11px;
  width: 350px;
}
.hotel-price .info-wrapper i {
  width: 17px;
  height: 17px;
  background-position: center;
}
.hotel-price .col-discount .price-discount {
  margin-left: 6px;
}
.hotel-price .icon.icon-guest {
  width: 14px;
  height: 14px;
  margin: 0;
}
.extra-bed-and-cradle {
  font-size: 16px !important;
  font-weight: 800;
  color: #03bbc6;
}

.pressButton {
  border-radius: 5px;
  background-color: #00000014;
  padding: 5px;
  font-weight: 800;
}

[role='tooltip'].popup-content {
  width: 360px;
  margin-right: 30px;
  border: 1px solid #01bac6;
  padding:10px;
  font-size: 14px;

}
.daily-price_description {
  font-size: 12px;
  font-style: italic;
}
.hotel-content__promo {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  padding: 6px 8px;
  right: 0px;
  top: 0px;
  background: #04b720;
  border-radius: 0px 4px;
}
.shields-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 20px 0 35px;
}

.shield {
  width: 150px;
  margin: 5px;
}
.ur-col-3 {
  padding: 0 10px 74px 0!important;
  display: flex;
  flex-direction: column!important;
  justify-content: space-between!important;
}
.ur-col {
  padding: 24px 0 24px 15px!important;
}
.bonus-container {
  text-align: center;
}

.bonus-container svg {
  display: inline;
  margin: 5px 10px -4px;
}

.link-bonus-popup {
  text-decoration: none!important;
  color: white!important;
  display: block!important;
  cursor: pointer!important;
}

.link-bonus-popup:hover {
  text-decoration: none!important;
  color: white!important;
  cursor: pointer!important;
}

.bonus-container span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #484151;
  border-bottom: 1px dashed #484151;
  cursor: default;
}
.upsale-modal-wrapper,
.mobile-modal-wrapper{
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: #1F1F1F;
}
.upsale-modal-wrapper{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upsale-modal-backdrop,
.mobile-modal-backdrop{
  opacity: 0.3;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  pointer-events: unset;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #272c36;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  pointer-events: none;
}
.upsale-modal-open,
.mobile-modal-open{
  overflow: hidden;
}
.upsale-modal,
.mobile-modal{
  width: 100%;
  animation-fill-mode: initial;
  display: none;
  overflow: visible;
  outline: 0;
  position: relative;
  z-index: 1050;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  max-width: 1150px!important;
  margin: 18px!important;
}
.mobile-modal{
  margin-top: 690px!important;
  border-radius: 20px !important;
  width: 89% !important;
}
.upsale-modal{
  max-width: 1160px;
  margin: 30px auto 0 auto;
  padding: 0px 16px;
  /* width: auto; */
}
.upsale-modal-content, .mobile-modal-content{
  position: relative;
  background-color: #fff;
  outline: 0;
}
.mobile-modal-content{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 8px 16px rgba(28, 28, 28, 0.12);

}
.upsale-modal-content{
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 16px;
}
.upsale-modal .rs-modal-body {
  background-color: #F5F5F5;
  border-radius: 8px;
  padding: 24px 16px 16px;
}
.upsale-modal .rs-modal-body,
.mobile-modal .rs-modal-body {
  margin-top: 0;
}
.mobile-modal .rs-modal-body{
  padding-bottom: 0px;
}
.upsale-modal__title {
  margin-bottom: 20px;
}

.upsale-room {
  background-color: #fff;
  padding: 4px;
  border-radius: 8px;
  flex-direction: column;
@media (min-width: 881px) {
  flex-direction: row;
}
}

.upsale-room__content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 24px;
@media (min-width: 881px) {
  margin-top: 0;
  margin-left: 24px;
}
}

.upsale-room__last-rooms {
  align-self: flex-end;
  display: inline-block;
  background: #DE210B;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #FFFFFF;
  padding: 6px 6.5px;
}

.upsale-room__title {
  position: relative;
  margin-bottom: 20px;
}
.upsale-room__title::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: -4px;
  left: 0;
  border-bottom: 1px dashed #CACACA;
}
.upsale-room__title,
.upsale-room__description{
  margin-right: 22px;
}
.upsale-room__description {
  line-height: 24px;
}

.upsale-room__row {
  margin-top: 16px;
}
.upsale-room__prop + .upsale-room__prop{
  margin: 0px 8px;
}

.upsale-room__prop {
  padding: 8px 8px 8px 32px;
  background-color: #F5F5F5;
  border-radius: 4px;
  position: relative;
}
.upsale-room__prop .hotel-icon{
  position: absolute;
  font-size: 16px;
  line-height: 16px;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9F9F9F;
}



.upsale-modal__bot {

  border-top: 3px dashed #FFFFFF;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
  margin: 16px -16px 0px -16px;
  position: relative;
}

.upsale-modal__reject {
  background: #F5F5F5;
  border-radius: 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 8px;

  color: #9F9F9F;
}
.upsale-modal__bot-right{
  display: flex;
  align-items: center;
}
.upsale-modal__extra-charge {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-right: 24px;
}

.upsale-modal__extra-charge span{
  font-weight: 600;
  color: #DE210B;
}

.upsale-modal__resolve {
  padding: 16px;
  background: #DE210B;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #FFFFFF;

}
.upsale-modal .rs-modal-header-close,
.mobile-modal .rs-modal-header-close{
  z-index: 1;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #ECECEC;
  font-weight: 900;
  cursor: pointer;
}
.upsale-modal .rs-modal-header-close{
  line-height: 11px;
  font-size: 11px;
  top: 32px;
  right: 32px;
  color: #9F9F9F;
}

.mobile-modal .rs-modal-header-close{
  top: 20px;
  right: 24px;
  color: #1F1F1F;
  line-height: 12.5px;
  font-size: 12.5px;
}
