.result-container__hotel {
    border: 1px solid #eceff5;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 40px;
    overflow: visible;
}

.result-container__hotel.open {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}

.result-container__hotel .result-container__hotel-main {
    display: flex;
}

.images-gallery > div {
    display: flex;
    min-width: 460px;
}

.images-gallery .thumbs-images {
    position: relative;
}

.images-gallery .thumbs-images .lightbox-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.images-gallery .thumbs-images .more-photo {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 84px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-photo {
    pointer-events: none;
}

.images-gallery .thumbs-images .react-lightbox {
    border-radius: 10px;
    margin-bottom: 2px;
    width: 98px;
    height: 84px;
}

.result-container__hotel
.images-gallery
.thumbs-images
.react-lightbox:nth-child(4) {
    margin-bottom: 0;
}

.images-gallery img {
    display: block;
    cursor: pointer;
}

.images-gallery .main-image img {
    margin-right: 2px;
    width: 360px;
    height: 342px;
    border-radius: 10px;
    object-fit: cover;
}

.main-image {
    margin-right: 2px;
    width: 360px;
    height: 342px;
    border-radius: 10px;
    object-fit: cover;
    display: flex;
    justify-content: space-evenly;
}

.result-container__hotel .hotel-content {
    padding: 24px 24px 15px 24px;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.result-container__hotel .hotel-content .hotel-content__title {
    margin-bottom: 28px;
}

.result-container__hotel .hotel-content .hotel-content__title * {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    /* color: #484151; */
    position: relative;
    text-decoration: none;
}

.result-container__hotel .hotel-content .hotel-content__title *::after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 0;
    border-bottom: 1px dashed #929292;
}

.hotel-content__last-number {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    padding: 6px 8px;
    right: 0px;
    top: 0px;
    background: #606e8c;
    border-radius: 0px 4px;
}

.item-position-absolute {
    position: absolute;
}

.hotel-content__options {
    display: flex;
    justify-content: flex-start;
}

.hotel-content__options .options-item {
    font-size: 14px;
    line-height: 16px;
    /* color: #484151; */
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 15px;
}

.hotel-content__options .options-item .icon {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    font-size: 18px;
}

.rs-popover-content .sub-title {
    font-weight: 500;
}

.rs-popover-content ul {
    list-style-type: none;
    padding: 0;
}

.rs-popover-content ul li {
    display: flex;
    padding-bottom: 3px;
}

.rs-popover-content ul .icon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    background-repeat: no-repeat;
    background-size: 100%;
}

.rs-popover-content p {
    font-size: 14px;
    display: flex;
}

.rs-popover-content p .icon {
    width: 24px;
    height: 24px;
    font-size: 22px;
}

.icon.icon-air-conditioning {
    background-image: url(/bookform/images/icons/air-conditioning.svg);
}

.icon.icon-armchair {
    background-image: url(/bookform/images/icons/armchair.svg);
}

.icon.icon-baby-bed {
    background-image: url(/bookform/images/icons/baby-bed.svg);
}

.icon.icon-balcony {
    background-image: url(/bookform/images/icons/balcony.svg);
}

.icon.icon-bath {
    background-image: url(/bookform/images/icons/bath.svg);
}

.icon.icon-bath-towels {
    background-image: url(/bookform/images/icons/bath-towels.svg);
}

.icon.icon-beakfast-light {
    background-image: url(/bookform/images/icons/beakfast_light.svg);
}

.icon.icon-carpet-covering {
    background-image: url(/bookform/images/icons/carpet-covering.svg);
}

.icon.icon-climate-control-system {
    background-image: url(/bookform/images/icons/climate-control-system.svg);
}

.icon.icon-coffee-machine {
    background-image: url(/bookform/images/icons/coffee-machine.svg);
}

.icon.icon-coffee-table {
    background-image: url(/bookform/images/icons/coffee-table.svg);
}

.icon.icon-cooking-stove {
    background-image: url(/bookform/images/icons/cooking-stove.svg);
}

.icon.icon-cosmetical-tools {
    background-image: url(/bookform/images/icons/cosmetical-tools.svg);
}

.icon.icon-courtyard-view {
    background-image: url(/bookform/images/icons/courtyard-view.svg);
}

.icon.icon-curbstone {
    background-image: url(/bookform/images/icons/curbstone.svg);
}

.icon.icon-desk {
    background-image: url(/bookform/images/icons/desk.svg);
}

.icon.icon-desk-lamp {
    background-image: url(/bookform/images/icons/desk-lamp.svg);
}

.icon.icon-dinner-light {
    background-image: url(/bookform/images/icons/dinner_light.svg);
}

.icon.icon-dishwasher {
    background-image: url(/bookform/images/icons/dishwasher.svg);
}

.icon.icon-double-bed {
    background-image: url(/bookform/images/icons/double-bed.svg);
}

.icon.icon-dresser {
    background-image: url(/bookform/images/icons/dresser.svg);
}

.icon.icon-dressing-gowns {
    background-image: url(/bookform/images/icons/dressing-gowns.svg);
}

.icon.icon-electronic-locks {
    background-image: url(/bookform/images/icons/electronic-locks.svg);
}

.icon.icon-exclusive-furniture {
    background-image: url(/bookform/images/icons/exclusive-furniture.svg);
}

.icon.icon-filter {
    background-image: url(/bookform/images/icons/filter.svg);
}

.icon.icon-forest-view {
    background-image: url(/bookform/images/icons/forest-view.svg);
}

.icon.icon-fresh-press {
    background-image: url(/bookform/images/icons/fresh-press.svg);
}

.icon.icon-fridge {
    background-image: url(/bookform/images/icons/fridge.svg);
}

.icon.icon-furniture-set {
    background-image: url(/bookform/images/icons/furniture-set.svg);
}

.icon.icon-glasses {
    background-image: url(/bookform/images/icons/glasses.svg);
}

.icon.icon-hair-dryer {
    background-image: url(/bookform/images/icons/hair-dryer.svg);
}

.icon.icon-hanger {
    background-image: url(/bookform/images/icons/hanger.svg);
}

.icon.icon-heating {
    background-image: url(/bookform/images/icons/heating.svg);
}

.icon.icon-high-ceilings {
    background-image: url(/bookform/images/icons/high-ceilings.svg);
}

.icon.icon-hygiene-products {
    background-image: url(/bookform/images/icons/hygiene-products.svg);
}

.icon.icon-information-card-menu {
    background-image: url(/bookform/images/icons/information-card-menu.svg);
}

.icon.icon-iron {
    background-image: url(/bookform/images/icons/iron.svg);
}

.icon.icon-ironing-board {
    background-image: url(/bookform/images/icons/ironing-board.svg);
}

.icon.icon-kettle {
    background-image: url(/bookform/images/icons/kettle.svg);
}

.icon.icon-kitchen {
    background-image: url(/bookform/images/icons/kitchen.svg);
}

.icon.icon-lamp {
    background-image: url(/bookform/images/icons/lamp.svg);
}

.icon.icon-living-area {
    background-image: url(/bookform/images/icons/living-area.svg);
}

.icon.icon-lock {
    background-image: url(/bookform/images/icons/lock.svg);
}

.icon.icon-loupe {
    background-image: url(/bookform/images/icons/loupe.svg);
}

.icon.icon-microwave {
    background-image: url(/bookform/images/icons/microwave.svg);
}

.icon.icon-mineral-water {
    background-image: url(/bookform/images/icons/mineral-water.svg);
}

.icon.icon-mini-bar {
    background-image: url(/bookform/images/icons/mini-bar.svg);
}

.icon.icon-mirror {
    background-image: url(/bookform/images/icons/mirror.svg);
}

.icon.icon-heating {
    background-image: url(/bookform/images/icons/heating.svg);
}

.icon.icon-mountain-view {
    background-image: url(/bookform/images/icons/mountain-view.svg);
}

.icon.icon-phone {
    background-image: url(/bookform/images/icons/phone.svg);
}

.icon.icon-qr-code {
    background-image: url(/bookform/images/icons/qr-code.svg);
}

.icon.icon-river-view {
    background-image: url(/bookform/images/icons/river-view.svg);
}

.icon.icon-room-service {
    background-image: url(/bookform/images/icons/room-service.svg);
}

.icon.icon-router {
    background-image: url(/bookform/images/icons/router.svg);
}

.icon.icon-safe {
    background-image: url(/bookform/images/icons/safe.svg);
}

.icon.icon-safety-system {
    background-image: url(/bookform/images/icons/safety-system.svg);
}

.icon.icon-satelit-tv {
    background-image: url(/bookform/images/icons/satelit_tv.svg);
}

.icon.icon-scales {
    background-image: url(/bookform/images/icons/scales.svg);
}

.icon.icon-security {
    background-image: url(/bookform/images/icons/security.svg);
}

.icon.icon-set-of-dishes {
    background-image: url(/bookform/images/icons/set-of-dishes.svg);
}

.icon.icon-shower-cabin {
    background-image: url(/bookform/images/icons/shower-cabin.svg);
}

.icon.icon-single-bed {
    background-image: url(/bookform/images/icons/single-bed.svg);
}

.icon.icon-sink {
    background-image: url(/bookform/images/icons/sink.svg);
}

.icon.icon-slippers {
    background-image: url(/bookform/images/icons/slippers.svg);
}

.icon.icon-sofa {
    background-image: url(/bookform/images/icons/sofa.svg);
}

.icon.icon-spa-light {
    background-image: url(/bookform/images/icons/spa_light.svg);
}

.icon.icon-square {
    background-image: url(/bookform/images/icons/square.svg);
}

.icon.icon-tea-set {
    background-image: url(/bookform/images/icons/tea-set.svg);
}

.icon.icon-terrace {
    background-image: url(/bookform/images/icons/terrace.svg);
}

.icon.icon-tiled-covering {
    background-image: url(/bookform/images/icons/tiled-covering.svg);
}

.icon.icon-times {
    background-image: url(/bookform/images/icons/times.svg);
}

.icon.icon-toilet-bowl {
    background-image: url(/bookform/images/icons/toilet-bowl.svg);
}

.icon.icon-tv {
    background-image: url(/bookform/images/icons/tv.svg);
}

.icon.icon-user {
    background-image: url(/bookform/images/icons/user.svg);
}

.icon.icon-wardrobe {
    background-image: url(/bookform/images/icons/wardrobe.svg);
}

.icon.icon-warm-floor {
    background-image: url(/bookform/images/icons/warm-floor.svg);
}

.icon.icon-wi-fi {
    background-image: url(/bookform/images/icons/wi-fi.svg);
}

.icon.icon-wi-fi-light {
    background-image: url(/bookform/images/icons/wi-fi_light.svg);
}

.icon.icon-writing-utensils {
    background-image: url(/bookform/images/icons/writing-utensils.svg);
}

.icon.icon-share {
    background-image: url(/bookform/images/icons/icon-share.png);
}

.result-container__hotel .hotel-content__rates {
    position: relative;
    margin-bottom: 16px;
    margin-top: 16px;
}

.result-container__hotel .nav-left,
.result-container__hotel .nav-right {
    position: absolute;
    width: 32px;
    height: 32px;
    top: calc(50% - 16px);
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    cursor: pointer;
}

.result-container__hotel .nav-left {
    left: -16px;
}

.result-container__hotel .nav-right {
    right: -16px;
}

.result-container__hotel .nav-left i,
.result-container__hotel .nav-right i {
    margin: 0;
}

.result-container__hotel .hotel-content__rates .rates-item {
    min-width: 147px;
    height: 56px;
    /* background: #ffffff; */
      border-width: 1px;
      border-style: solid;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 11.6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 10px 40px 10px 12px;
    display: flex;
    align-items: center;
    cursor: grabbing;
}

.result-container__hotel .hotel-content__rates .rates-item p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 2;
    max-height: 59px;
    -webkit-box-orient: vertical;
}

.result-container__hotel .hotel-content__rates .rates-item.has-bg {
    background-size: cover;
    color: #fff;
}

.result-container__hotel .hotel-content__description {
    font-size: 14px;
    line-height: 22px;
    /* color: #484151; */
    margin-bottom: 14px;
}

.result-container__hotel .hotel-content__description a {
    color: #01bac6;
    text-decoration: none;
}

.result-container__hotel .hotel-content__footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
}

.result-container__hotel .hotel-content__footer > div {
    margin-left: 13px;
    display: flex;
    align-items: center;
}

.result-container__hotel .hotel-content__footer .col-buy {
    margin-left: 11px;
}

.result-container__hotel .hotel-content__footer .col-desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    /* color: #484151; */
}

.info-wrapper {
    line-height: 0.8;
    display: inline-block;
    margin-left: 10px;
}

.info-wrapper i {
    /* background-color: #01bac6; */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-position: 9px center;
    background-size: initial;
    margin: 0;
    text-align: center;
    font-size: 7px;
    /* color: #fff; */
    line-height: 20px;
}

.result-container__hotel .hotel-content__footer .col-discount {
    display: block;
    text-align: right;
}

.result-container__hotel .result-container__hotel-more {
    display: none;
    padding-left: 15px;
    padding-right: 15px;
}

.result-container__hotel.open .result-container__hotel-more {
    display: block;
}

.col-discount .price-percent {
    background: #01bac6;
    border-radius: 2px;
    padding: 2px;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.col-discount .price-discount {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    text-decoration-line: line-through;
    color: #01bac6;
}

.ur-btn.ur-btn-buy {
    /* background: #ffbd00; */
    border-radius: 8px;
    /* color: #fff; */
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-decoration: none;
    padding: 8px 17px;
    border: 0;
}

.ur-btn.ur-btn-buy.not-sale {
    /* background: #eceff5;
                            color: #484151; */
    cursor: default;
}

.ur-btn-buy .icon {
    margin-left: 10px;
}

.result-container__hotel .result-container__hotel-more .ur-row {
    display: flex;
    padding: 0;
}

.result-container__hotel .result-container__hotel-more .row-header {
    padding: 12px 0;
    /* background: #eceff5; */
}

.result-container__hotel .result-container__hotel-more .row-header .ur-col {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* color: #929292; */
    padding-left: 24px;
    position: relative;
    box-sizing: border-box;
}

.result-container__hotel
.result-container__hotel-more
.row-header
.ur-col::after {
    content: "";
    width: 1px;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #c4c4c4;
    position: absolute;
}

.result-container__hotel
.result-container__hotel-more
.row-header
.ur-col:last-child:after {
    display: none;
}

.result-container__hotel .result-container__hotel-more .ur-col.ur-col-1 {
    width: 320px;
}

.result-container__hotel .result-container__hotel-more .ur-col.ur-col-2 {
    width: 520px;
}

.result-container__hotel .result-container__hotel-more .ur-col.ur-col-3 {
    flex-grow: 1;
}

.react-multi-carousel-item {
    width: 155px !important;
    flex: 0 0 148px !important;
}

.react-multi-carousel-list {
    display: block;
    overflow: hidden;
    position: absolute;
    width: 101%;
}

.result-container__hotel .hotel-content__rates {
    height: 56px;
}

.lb-icon-zoomin,
.lb-icon-zoomout,
.lb-icon-rotate {
    display: none !important;
}

.subscription-button {
    border-radius: 8px;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    padding: 8px;
    background-color: #1F1F1F;
    cursor: pointer;
    position: relative;
    display: flex;
    overflow: visible;
    align-items: center;
    overflow: visible;
    box-sizing: content-box;
}

.subscription-button::after {
    content: "Уведомить о доступности";
    visibility: hidden;
    width: 230px;
    height: 23px;
    background-color: #1F1F1F;
    color: #fff;
    text-align: center;
    border-radius: 16px;
    padding: 11px 16px;
    position: absolute;
    z-index: 1000;
    top: -129%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    /*transition: opacity 0.3s;*/
    pointer-events: none;
}

.subscription-button::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 9" fill="%231F1F1F"><path d="M13.3008 7.66864C13.1899 7.90254 12.819 9.00003 11.9999 9.00003C11.1809 9.00003 10.8164 7.91696 10.7091 7.68981C9.15165 4.3932 5.94904 5.20082e-07 0 0L24 2.09815e-06C18.0623 1.57906e-06 14.8605 4.37704 13.3008 7.66864Z" fill="%231F1F1F"/></svg>');
    width: 24px;
    height: 5px;
    position: absolute;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -3px;
    opacity: 0;
    /*transition: opacity 0.3s;*/
}

.subscription-button:hover::before {
    opacity: 1;
}

.subscription-button:hover::after {
    visibility: visible;
    opacity: 1;
}

.feature__container {

}

.feature__element {
    margin-top: 15px;
}

.feature__category__name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #9F9F9F;
}

.features {
    display: flex;
    border-radius: 12px;
    padding: 10px 10px 10px 0;
    background-color: #F5F5F5;
    margin-top: 10px;
    flex-wrap: wrap;
}

.internal__feature {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.internal__feature img {
    width: 24px;
}

.feature__name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    color: #1F1F1F;
    margin-left: 7px;
}

.features__button {
    color: #01BAC6;
    font-family: Inter;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    text-decoration: none;
}