.icon-button {
  display: flex;
  background: #948268;
  border-radius: 8px 8px;
  height: 52px;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.icon-button.w60 {
  width: 60px;
}

.icon-button.w200 {
  width: 200px;
}

.inter__family {
  font-family: "Inter", sans-serif!important;
}

.icon-button__icon {
  fill: #fff;
  width: 36px;
  height: 36px;
  display: flex;

  background-position: center;
  background-size: cover;
  object-fit: cover;
}

.icon-button__icon.warning {
  background-image: url(/bookform/images/icons/warning.png);
}

.icon-button__icon.calendar {
  background-image: url(/bookform/images/icons/calendar.png);
}