.modal-header {
  display: flex;
  justify-content: space-between;
}
.modal-header button,
.modal-header a {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 20px;
  text-decoration: none;
}
.modal-header button i,
.modal-header a i {
  margin: 0 4px;
}
.modal-header button.active,
.modal-header a.active {
  color: #948268;
}
.modal-hotel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  overflow-x: scroll;
  display: none;
}
.modal-hotel.show {
  display: block;
  padding-bottom: 77px;
}
.modal-hotel-content {
  padding: 12px 16px;
  position: relative;
}
.modal-hotel-content .hotel-content__last-number {
  position: relative;
}
.modal-hotel-content .hotel-content__title button {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  text-decoration: none;
  text-align: left;
  margin-bottom: 5px;
}
.modal-hotel-content .hotel-content__last-number {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 6px 8px;
  position: relative;
  right: 0px;
  top: 0px;
  background: #ff2e00;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 16px;
}
.modal-hotel-content .hotel-content__options {
  justify-content: start;
}
.modal-hotel-content .hotel-content__options .options-item {
  margin-right: 26px;
  font-size: 12px;
}
.modal-hotel-content .hotel-content__options .options-item .icon,
.result-container-mobile__hotel .hotel-content__options .options-item .icon {
  font-size: 14px;
}
.modal-hotel-content .sub-title {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #484151;
}
.modal-hotel-room__descritpion {
  margin-bottom: 16px;
  height: 190px;
  overflow: hidden;
  position: relative;
}
.modal-hotel-room__descritpion.show {
  height: auto;
}
.modal-hotel-room__descritpion:not(.show):after {
  content: "";
  display: block;
  height: 64px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(rgb(255 255 255 / 0%), rgb(255 255 255));
}
.modal-hotel-content .ur-btn.ur-btn-secondary {
  display: block;
  width: 100%;
}
.modal-hotel-room__descritpion .hotel-content__description {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}
.modal-hotel-room__descritpion ul {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 17px 0;
}
.modal-hotel-room__descritpion ul li {
  margin: 5px 0;
  font-size: 12px;
}
.modal-hotel-room__descritpion ul li i {
  margin-right: 7px;
  font-size: 16px;
}
.modals {
  display: none;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: scroll;
  z-index: 999;
}
.modals.show {
  display: block;
}
