.container.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.container.px-8 .ur-row {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: -8px;
  margin-right: -8px;
}
.result-container-mobile__hotel {
  border: 1px solid #eceff5;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 23px;
}
.result-container-mobile__hotel.open {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}
.result-container-mobile__hotel .images-gallery {
  display: flex;
  width: 100%;
}
.result-container-mobile__hotel .images-gallery .main-image {
  width: 100%;
}
.progress-circle__mobile {
  margin-left: -130px;
}
.result-container-mobile__hotel .images-gallery .thumbs-images {
  position: relative;
}
.result-container-mobile__hotel
  .images-gallery
  .thumbs-images
  .lightbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.result-container-mobile__hotel .images-gallery .thumbs-images .more-photo {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 1px);
  height: 84px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.more-photo {
  pointer-events: none;
}
.result-container-mobile__hotel .images-gallery .thumbs-images .react-lightbox {
  margin-left: 1px;
  width: 98px;
  height: 84px;
}
.result-container-mobile__hotel .images-gallery img {
  display: block !important;
  cursor: pointer;
}
.slick-slider {
  position: relative;
  overflow: hidden;
}
.slick-slider .slick-track {
  display: flex;
}
.slick-slider .slick-dots {
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 13px;
  width: 100%;
}
.slick-slider .slick-dots li button {
  display: none;
}
.slick-slider .slick-dots li {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 50%;
  margin: 0 3px;
}
.slick-slider .slick-dots li.slick-active {
  background-color: #fff;
}
.result-container-mobile__hotel .hotel-content {
  padding: 15px;
  position: relative;
}
.result-container-mobile__hotel .hotel-content .hotel-content__title {
  margin-bottom: 5px;
}
.result-container-mobile__hotel .hotel-content .hotel-content__title * {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  text-decoration: none;
  text-align: left;
}
.result-container-mobile__hotel .hotel-content .hotel-content__title *::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: -4px;
  left: 0;
  border-bottom: 1px dashed #929292;
  display: none;
}
.result-container-mobile__hotel .hotel-content__last-number {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  padding: 6px 8px;
  position: relative;
  right: 0px;
  top: 0px;
  /* background: #ff2e00; */
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 8px;
}
.result-container-mobile__hotel .hotel-content__options {
  display: flex;
  margin-bottom: 6px;
  justify-content: flex-start;
}
.result-container-mobile__hotel .hotel-content__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.result-container-mobile__hotel .hotel-content__options .options-item {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 25px;
}
.result-container-mobile__hotel .hotel-content__options .options-item .icon {
  margin-right: 9px;
  width: 15px;
  height: 15px;
}
.result-container-mobile__hotel .hotel-content__footer .col-desc {
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  font-size: 10px;
}
.result-container-mobile__hotel .hotel-content__footer .col-discount {
  display: block;
}
.result-container-mobile__hotel .hotel-content__footer .col-price {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.result-container-mobile__hotel .result-container__hotel-more {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
}
.result-container-mobile__hotel.open .result-container__hotel-more {
  display: block;
}
.result-container-mobile__hotel .col-discount .price-percent {
  background: #01bac6;
  border-radius: 2px;
  padding: 2px;
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
.result-container-mobile__hotel .col-discount .price-discount {
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: line-through;
  color: #01bac6;
  display: inline-block;
  margin-left: 9px;
}
.result-container-mobile__hotel .ur-btn.ur-btn-buy {
  /* background: #ffbd00; */
  border-radius: 8px;
  /* color: #fff; */
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-decoration: none;
  padding: 6px 12px;
  border: 0;
  margin-top: 8px;
}
.result-container-mobile__hotel .ur-btn.ur-btn-buy.not-sale {
  background: #ff2e00;
}
.result-container-mobile__hotel .ur-btn.ur-btn-buy.disabled {
	background: #ECEFF5;
	color: #484151
}
.result-container-mobile__hotel .ur-btn-buy .icon {
  margin-left: 10px;
}
.hotel-content__last-number__mobile {
  font-weight: 400;
  font-size: 11px;
  line-height: 5px;
  color: #ffffff;
  padding: 6px 8px;
  right: 0px;
  top: 0px;
  background: #ff2e00;
  border-radius: 0px 4px;
}
.ur-col-mobile {
  padding: 0!important;
}
.shields-container-mobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.loader__plug__mobile {
  padding: 130px;
  height: 360px!important;
}
.guests-info-mobile {
  display: inline;
}
.col-price-mobile {
  display: inline-block;
}

.sold__out__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
  height: 184px;
  border: 1px solid #000000;
  border-radius: 24px;
  margin-top: 20px;
}

.sold__out__soldOut {
  width: 100%;
  flex-grow: 1;
  /* padding-top: 18px; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* vertical-align: top; */
  color: #484151;
  background-color: #ECEFF5;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subscription-button__mobile {
  background-color: #1F1F1F;
  border-radius: 16px;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}


.feature__container__mobile {

}

.feature__element__mobile {
  margin-top: 15px;
}

.feature__category__name__mobile {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #9F9F9F;
}

.features__mobile {
  display: flex;
  border-radius: 12px;
  padding: 10px 10px 10px 0;
  background-color: #F5F5F5;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column;
}

.internal__feature__mobile {
  display: flex;
  align-items: center;
  margin: 5px 0 10px 5px;
}

.internal__feature__mobile img {
  width: 16px;
}

.feature__name__mobile {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-align: left;
  color: #1F1F1F;
  margin-left: 7px;
}

.features__button__mobile {
  color: #01BAC6;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-decoration: none;
  margin: 5px 0;
}
