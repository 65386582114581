.modal-hotel-rates {
  padding: 12px 16px;
  position: relative;
}
.modal-hotel-rates .h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.modal-hotel-rates .rate-description {
  border-radius: 8px;
  /* background: #ffffff; */
    /* border: 1px solid #dfe5ec; */
    border-width: 1px;
    border-style: solid;
  box-sizing: border-box;
  padding: 12px;
  margin-bottom: 12px;
}
.modal-hotel-rates .rate-description .h4 {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  width: 85%;
}
.modal-hotel-rates .rate-description p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.modal-hotel-rates .rate-description .subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.modal-hotel-rates .rate-description.has-bg.show {
  background: #fff !important;
}
.modal-hotel-rates .rate-description.has-bg {
  border: 1px solid transparent;
}
.modal-hotel-rates .rate-description.show {
  border: 1px solid #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
}
.modal-hotel-rates .rate-description.has-bg:not(.show) .h4,
.modal-hotel-rates .rate-description.has-bg:not(.show) p {
  color: #fff;
}
.modal-hotel-rates .hotel-content__services .services-item {
  text-align: center;
  align-items: center;
}
.modal-hotel-rates .hotel-content__services .services-item .icon {
  margin: 0;
}
.modal-hotel-rates .hotel-content__services {
  width: 100%;
  margin-bottom: 15px;
}
.modal-hotel-rates .ur-btn.ur-btn-secondary {
  width: 100%;
}
.modal-hotel-rates .hotel-content__services .services-wrapper {
  margin-bottom: 4px;
}
.modal-hotel-rates .hotel-price {
  justify-content: space-between;
}
.modal-hotel-rates .hotel-price .col-price {
  font-size: 14px;
}
.modal-hotel-rates .hotel-price .hotel-price__col:nth-child(2) {
  width: 31%;
}
.modal-hotel-rates .hotel-price .hotel-price__col:first-child {
  text-align: left;
  width: 23%;
}
.modal-hotel-rates .hotel-price .col-discount .price-discount {
  font-size: 10px;
}
.modal-hotel-rates .hotel-price .ur-btn.ur-btn-buy {
  font-size: 12px;
  padding: 6px 12px;
}
.modal-hotel-rates .info-wrapper {
  margin: 0;
}
.modal-hotel-rates .col-buy {
  margin-left: 12px;
}
.modal-hotel-rates .col-rate__footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.modal-hotel-rates .col-rate__footer .col-discount {
  display: flex;
  align-items: center;
}
.modal-hotel-rates .col-rate__footer .col-discount .price-percent {
  background: #ff8d4e;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}
.modal-hotel-rates .col-rate__footer .col-discount .price-discount {
  font-size: 10px;
  line-height: 16px;
  margin-left: 8px;
}
.modal-hotel-rates .col-rate__footer .ur-btn.ur-btn-buy {
  font-size: 12px;
  padding: 4px 12px;
}
.modal-hotel-rates
  .rate-description.has-bg:not(.show)
  .col-discount
  .price-discount,
.modal-hotel-rates .rate-description.has-bg:not(.show) .col:first-child p {
  color: #fff;
}
.modal-hotel-rates .rate-description {
  position: relative;
}
.modal-hotel-rates .guests-info-text-in-rate {
  text-align: center;
  margin-bottom: 5px;
}
.modal-hotel-rates .rate-description .close {
  background: #eceff5;
  border-radius: 50%;
  color: #929292;
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 10px;
  position: absolute;
  right: 12px;
  top: 12px;
}
.extra-bed-and-cradle {
  font-size: 16px !important;
  font-weight: 800;
  color: #03bbc6;
  margin-bottom: 5px;
}

[role='tooltip'].popup-content {
  width: 220px;
  margin-left: 8px;
  border: 1px solid #01bac6;
  padding:10px;
  font-size: 12px;

}
.daily-price_description {
  font-size: 10px;
  font-style: italic;
}
.hotel-content__promo {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  padding: 6px 8px;
  right: 0px;
  top: 0px;
  background: #04b720;
  border-radius: 0px 4px;
}
.bonus-container {
  text-align: center;
  margin-top: 10px;
}

.bonus-container svg {
  display: inline;
  margin: 5px 10px -4px;
}

.link-bonus-popup {
  text-decoration: none!important;
  color: white!important;
  display: block!important;
  cursor: default!important;
}

.link-bonus-popup:hover {
  text-decoration: none!important;
  color: white!important;
}

.bonus-container span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: default;
}
.mobile-modal__hedaer {
  padding: 15px 80px 24px 24px;
  position: relative;
}
.mobile-modal__hedaer::after{
  content: "";
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0px;
  height: 1px;
  background: #ECECEC;
}
.mobile-modal__content {
  margin-top: 24px;
}
.upsale-mobile {
}
.upsale-mobile__gallery {
  padding: 0px 9px;
}
.upsale-mobile__gallery .slick-slider{
  border-radius: 16px;
}
.upsale-mobile__content{
  margin-top: 16px;
  padding: 0px 24px;
}
.upsale-mobile__last-rooms {
  padding: 6px 8px;
  display: inline-block;
  color: #fff;
  background: #DE210B;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

}
.upsale-mobile__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 6px;
}
.upsale-mobile__description {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}
.upsale-mobile__description.short{
  -webkit-line-clamp: 3; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}
.upsale-mobile__read-more {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
}
.upsale-mobile__props {
  margin-top: 16px;
}
.upsale-mobile__prop {
  margin-top: 0px;
  padding: 4px 8px;

  background: #F5F5F5;
  border-radius: 4px;
  color: #484151;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.upsale-mobile__prop span{
  /* color: #929292; */
  margin-right: 8px;
}
.upsale-mobile__prop+.upsale-mobile__prop{
  margin-left: 8px;
}

.upsale-mobile__bottom {
  position: relative;
  padding: 24px 16px;
  margin-top: 24px;
}
.upsale-mobile__bottom::after{
  content: "";
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 1px;
  background: #ECECEC;
}
.upsale-mobile__extra-charge {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.upsale-mobile__resolve {
  display: block;
  margin-top: 16px;
  padding: 10px 16px;
  background: #DE210B;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;

}
.upsale-mobile__reject {
  display: block;
  width: 100%;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #9F9F9F;
  margin-top: 16px;
}